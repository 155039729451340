import React from "react";
import { useEffect, useState } from "react";

import { searchSubstringInArrayObjects } from "../utils/constants";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import {
  getAccountStatementAPI,
  getBetsFromAccountLogs,
  getCompleteGamesAPI,
} from "../service/AuthService";
import { Link } from "react-router-dom";

export default function CompleteGames() {
  const navigate = useNavigate();

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getAccountStatement = async () => {
    setLoading(true);
    const { response } = await getCompleteGamesAPI();
    if (response?.completedGames) {
      const reversedData = {
        ...response,
        completedGames: response.completedGames.reverse(),
      };
      setData(reversedData);
    } else {
      setData(response);
    }
    setLoading(false);
  };
  useEffect(() => {
    getAccountStatement();
  }, []);

  const [activeTab, setActiveTab] = useState("CRICKET");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      {loading ? (
        <>
          <div className="position-fixed w-100 h-100 d-flex align-items-center justify-content-center loader">
            <img src="/logo.png" alt="Loader" class="flip-vertical-right" />
          </div>
        </>
      ) : null}
      <div className="position-relative border-none">
        <Link className="btn btn-primary backbutton w-100" to="/">
          Back to Main Menu
        </Link>
        <div className="statement-tabs">
          <button
            type="button"
            className={`btn ${
              activeTab === "CRICKET"
                ? "bg-primary text-white active"
                : "bg-white text-primary"
            }`}
            onClick={() => handleTabClick("CRICKET")}
          >
            CRICKET
          </button>
          <button
            type="button"
            className={`btn ${
              activeTab === "CASINO"
                ? "bg-primary text-white active"
                : "bg-white text-primary"
            }`}
            onClick={() => handleTabClick("CASINO")}
          >
            CASINO
          </button>
        </div>
        <div className="container-fluid p-0">
          <main className="warp">
            {activeTab === "CRICKET" && (
              <div>
                <main className="warp">
                  <div>
                    {/* <div className="old-matches-list ">
                      <div className="TeamName">
                        <Link to="/complete_detail">Sydney Sixers VS Melbourne Stars (T-20)</Link>
                      </div>
                      <div className="old-match-details">
                        <Link to="/complete_detail">
                          <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <tbody>
                              <tr>
                                <td width="1%">&nbsp;</td>
                                <td className="GameList" style={{ verticalAlign: 'top' }}>
                                  <table width="99%" border="0" cellSpacing="0" cellPadding="0">
                                    <tbody>
                                      <tr>
                                        <td className="GameList" align="center">26-12-2023 12:35:00 PM</td>
                                      </tr>
                                      <tr>
                                        <td className="GameList" align="center">MATCH BETS : <span>0</span></td>
                                      </tr>
                                      <tr>
                                        <td className="GameList" align="center">Session Bets : <span>0</span></td>
                                      </tr>
                                      <tr>
                                        <td className="GameList text-danger" align="center">Loss coins : <span>937</span></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td width="1%">&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </Link>
                      </div>
                    </div> */}

                    {data?.completedGames?.map((item) => (
                      <div
                        onClick={() =>
                          navigate(`/complete_detail/${item?.accountLogId}`, {
                            state: {
                              item,
                            },
                          })
                        }
                        className="old-matches-list "
                      >
                        <div className="TeamName">
                          <Link>{item?.eventName}</Link>
                        </div>
                        <div className="old-match-details">
                          <a>
                            <table
                              width="100%"
                              border="0"
                              cellSpacing="0"
                              cellPadding="0"
                            >
                              <tbody>
                                <tr>
                                  <td width="1%">&nbsp;</td>
                                  <td
                                    className="GameList"
                                    style={{ verticalAlign: "top" }}
                                  >
                                    <table
                                      width="99%"
                                      border="0"
                                      cellSpacing="0"
                                      cellPadding="0"
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            className="GameList"
                                            align="center"
                                          >
                                            {moment(
                                              item?.eventDateTime
                                            ).format("YYYY-MM-DD : HH:MM")}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            className="GameList"
                                            align="center"
                                          >
                                            Won By : {item?.wonBy}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            className="GameList"
                                            align="center"
                                          >
                                            MATCH BETS : {item?.matchBetCount}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            className="GameList"
                                            align="center"
                                          >
                                            Session Bets :{" "}
                                            {item?.sessionBetCount}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            className="GameList"
                                            align="center"
                                          >
                                            {item?.winLoss >= 0 ? (
                                              <span
                                                style={{
                                                  color: "rgb(3, 117, 204)",
                                                }}
                                              >
                                                Win coins : {item?.winLoss}
                                              </span>
                                            ) : (
                                              <span
                                                style={{
                                                  color: "red",
                                                }}
                                              >
                                                Loss coins : {item?.winLoss}
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td width="1%">&nbsp;</td>
                                </tr>
                              </tbody>
                            </table>
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </main>
              </div>
            )}
            {activeTab === "CASINO" && (
              <div>
                <h5 className="mt-3 text-center">CASINO</h5>
              </div>
            )}
          </main>
        </div>
      </div>
    </>
  );
}
